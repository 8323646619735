.timelineInfo p {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.timelineInfo h4 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 20px;
}

.patientTimelineCostDetails {
  margin-bottom: 10px;
}

.timelineInfo button {
  margin-right: 10px;
}

.patientTimelineCostDetails .ant-col {
  margin-right: 20px;
}

.patientTimelineCostDetails .ant-col svg {
  margin-right: 5px;
}

.ant-timeline-item-head {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  background-color: #ffffff;
  border-width: 10px !important;
  border-radius: 50%;
}

.ant-timeline .ant-timeline-item-tail {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 9px;
  height: calc(100% - 10px);
  border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}
