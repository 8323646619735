.appointmentsFullList .appointmentsListItem {
  background: #dfdfdf80;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
}

.appointmentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rightItem {
    display: flex;
    align-items: center;
  }
  .doctorColor {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}

.appointmentItem {
  .info {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 10px;
  }
  .date {
    display: flex;
    align-items: center;
    label {
      display: block;
      b {
        margin-left: 10px;
        font-size: 15px;
      }
    }
    span {
      font-weight: bold;
    }
    svg {
      vertical-align: middle;
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
  button {
    border: none;
    box-shadow: none;
    span {
      font-size: 25px;
    }
  }
}

.appointmentTodayList .ant-collapse-header {
  align-items: center !important;
}

.titielAppointment {
  font-size: 18px;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
}

.appointmentTodayList {
  border: none;
  background: transparent;
}

.appointmentTodayList .ant-collapse-item {
  border: 1px solid #ccc;
  margin-top: 20px;
  border-radius: 10px;
  background: white;
}

.appointmentTodayList .ant-collapse-content.ant-collapse-content-active {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.appointmentPage {
}
