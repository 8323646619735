@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lusitana:wght@400;700&family=Open+Sans:wght@700&display=swap");
html body div {
  font-family: "Lato", sans-serif;
}
.hide-md {
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
}

.show-md {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

.childBodyContainer {
  padding: 24px;
  background: white;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 20px 9px 44px rgba(0, 0, 0, 0.12);
}

.mainContainer {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 4px 10px rgb(0 0 0 / 32%);
  min-height: calc(100vh - 101px);
}

.customDivider {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.searchPatients .ant-form-item {
  margin: 0;
}

.ant-tabs {
  .ant-tabs-ink-bar {
    background: #3399cc;
  }
  .ant-tabs-nav {
    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 16px;
      color: #3399cc;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
}
