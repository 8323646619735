.appointmentModal .ant-modal-content {
    padding: 0;
}

.appointmentModalHeader {
    padding: 15px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    color: white;
}

.appointmentModal .ant-modal-body {
    padding: 20px;
}