.logoMedic {
  background-color: rgb(255 255 255 / 50%);
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.logoMe {
  padding: 11px 10px;
  text-align: center;
}

.logoMe svg {
  width: 100%;
}

.logoMe img {
  width: 100%;
}

.header {
  background-color: white;
  padding: 0;
  color: white;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
}
.mainHeader .ant-select-selection-search-input {
  border-radius: 30px;
  font-size: 18px;
}

.mainLayout {
  background: #f8f8f7;
}
.companyName {
  padding: 0px 10px;
  font-weight: bold;
  height: 64px;
  color: black;
  font-size: 17px;
  font-family: fantasy;
  letter-spacing: 0.06em;
}

aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger {
  background: #fbfbfb;
  border-right: 1px solid #cacaca;
}

.ant-layout-sider ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  background: #fbfbfb;
}

.sidebar .ant-layout-sider-children {
  ul {
    li {
      font-weight: bold;
    }
  }
}

aside .ant-layout-sider-trigger {
  background: #fbfbfb;
  border-right: 1px solid #cacaca;
}

.globalPatientSearch {
  margin: 0 10px;
  height: 40px;
}

.globalPatientSearch span.ant-select-clear {
  top: 16px !important;
  right: 34px !important;
}

.mainHeader a.ant-dropdown-trigger {
  margin-right: 20px;
}

.mainHeader {
  display: flex;
  align-items: center;
}

.mainHeader input {
  height: 30px;
}

.ant-dropdown-trigger.userMenu {
  margin: 0 10px;
  cursor: pointer;
  color: black;
  position: relative;
  top: -2px;
}

.mobileNavigation {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
  button {
    height: 50px;
  }
  .ant-btn-group {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

li.ant-menu-item.ant-menu-item-selected {
  color: white;
  background: #3399cc;
}

.ant-btn-primary {
  color: #fff;
  background: #3399cc;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-layout.ant-layout-has-sider {
  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  aside.ant-layout-sider {
    display: none;
  }
}

.userProfile {
  padding: 7px;
  background: #3399cc;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
}

.spinnerContainer {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  background: rgb(255 255 255 / 72%);
  overflow: hidden;
}
.userMenuContainer {
  min-width: 210px !important;
}

div .userMenuContainer ul.ant-dropdown-menu li.ant-dropdown-menu-item {
  border-bottom: 1px solid gray;
  border-radius: 0 !important;
  padding: 0;
}

div
  .userMenuContainer
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item:first-child {
  border: none;
  padding: 0;
}

.userMenuItem {
  padding: 10px 5px;
}

div
  .userMenuContainer
  ul.ant-dropdown-menu
  li.ant-dropdown-menu-item:last-child {
  border: none;
}

.ant-layout-sider-children ul li {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.userMenu .username {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.settingsMenuItem {
  position: absolute !important;
  bottom: 50px;
}

.settingsOptions {
  color: #99a1b7;
  font-size: 27px;
  cursor: pointer;
  margin: 0 20px;
}
