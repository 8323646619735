.eventsChild {
  padding: 5px 10px;
  border-radius: 2px !important;
  p {
    margin: 0;
    color: black;
  }
}

.fc {
  .fc-toolbar-title {
    text-transform: capitalize;
  }
  a {
    color: black;
    text-transform: capitalize;
  }
  a.fc-col-header-cell-cushion {
    font-size: 20px;
    padding: 10px;
    display: block;
  }
  .fc-button-primary {
    background-color: #3399cc;
    border-color: #3399cc;
    margin: 0 2px;
    &:disabled {
      background-color: #3399cc;
      border-color: #3399cc;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

table.fc-scrollgrid.fc-scrollgrid-liquid tr td:first-child {
  padding-left: 5px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid tr td:last-child {
  padding-right: 5px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid tr td {
  padding-bottom: 2px;
}
