.ant-picker-calendar-header div label:last-child {
    display: none;
}

.ant-picker-calendar-header div label {
    border-radius: 5px !important;
}

.selectWeek {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    max-width: 260px;
    margin: 0 auto 20px;
}

.selectWeek button {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.calendar_default_rowheader .calendar_default_rowheader_inner {
    background: #fdfdfd;
    text-align: center;
}

.calendar_default_colheader .calendar_default_colheader_inner {
    background: #fefefe;
}

.shift .calendar_default_event_inner {
    border: 1px solid #ccc;
    border-radius: 5px;
	font-weight: bold;
    padding: 5px 10px;
}