.totalDaysContainer {
  .ant-col {
    padding: 27px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid #f1f1f4;
    background: white;
    margin-bottom: 15px;
    margin-right: 15px;
    .days {
      font-weight: bold;
    }
  }
}

.warningLeave svg {
  color: orange;
  font-size: 17px;
}

.warningLeave {
  text-align: center;
}
