.userPhoto {
  // width: 120px;
  // height: 120px;
  // border-radius: 50%;
  // display: flex;
  // justify-content: space-between;
  // margin: auto;
  // margin-bottom: 20px;
  // overflow: hidden;
  // position: relative;
  // img {
  // 	object-fit: contain;
  // 	width: 100%;
  // 	height: 100%;
  // 	border-radius: 50%;
  // }
  button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: white;
    border: none;
    left: 0;
    right: 0;
    margin: auto;
    height: 30px;
    overflow: hidden;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    button {
      display: block;
    }
  }
}

.languageSelect {
  position: absolute;
  right: 0;
  top: 0;
}

.profileHeader {
  // background-image: url('../../assets/images/headerbg.png');
  background-color: white;
  background-repeat: no-repeat;
  background-position: right;
  padding: 27px;
  border-radius: 10px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #f1f1f4;
  position: relative;
  .userData {
    display: flex;
    align-items: center;
  }
  .userInfo {
    margin-left: 10px;
    .name {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
    }
    .position {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
      letter-spacing: 0.15px;
      color: #a8a9aa;
    }
    .gender {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #a8a9aa;
    }
  }

  .userPhoto {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    button {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: white;
      border: none;
      left: 0;
      right: 0;
      margin: auto;
      height: 30px;
      overflow: hidden;
      font-size: 20px;
      cursor: pointer;
      display: none;
    }
    &:hover {
      button {
        display: block;
      }
    }
  }
}

.userTabs .ant-tabs-nav {
  background: white;
  margin-top: 20px;
  padding: 5px 20px 5px;
}

.userTabs .ant-tabs-nav .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: transparent;
}

.userInfoBody {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 20px 9px 44px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
  .containerBody {
    display: flex;
    flex-flow: wrap;
  }
  .info {
    width: 33.333%;
    margin-bottom: 20px;
    span {
      display: flex;
      font-weight: bold;
    }
  }
  .editUser {
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 25px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    .editUser {
      display: block;
    }
  }
}

.userProfileContainer {
  background: #f5f5f5;
  height: 100%;
  margin: 0 30px 0 0;
  border-radius: 10px;
  padding: 20px;
}

.editUserProfile {
  position: absolute;
  right: 50px;
  cursor: pointer;
  font-size: 23px;
}

.list {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  .payment {
    display: flex;
    align-items: center;
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      margin: 0 10px;
    }
    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      margin: 0 10px;
    }
  }
}

.userProfilePayment {
  .ant-card-body {
    box-shadow: 20px 9px 44px rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 10px;
    padding: 20px 40px;
    margin: 20px 0 30px 0;
  }
}
