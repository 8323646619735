.selectLanguage {
  cursor: pointer;
  margin: 0 10px;
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  height: 40px;
  padding: 0 12px;
  color: black;
}

.languageOption {
  width: 100%;
}

.ant-dropdown-trigger.selectLanguage .ant-space-item {
  font-size: 20px;
  margin-left: 5px;
  text-transform: uppercase;
}
