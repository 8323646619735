.homeDatePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  max-width: 200px;
  margin: auto;
  .date {
    .ant-picker {
      border: none;
      font-size: 30px;
      input {
        font-size: 20px;
        width: 90px;
        text-align: center;
      }
      span {
        display: none;
      }
    }
  }
  .prev,
  .next {
    cursor: pointer;
  }
}

.noData {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  font-weight: bold;
}

.dashboardTitle {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
}

.homeStatisticCard {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(0 0 0 / 17%);
  padding-top: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  .card {
    padding: 10px;
    width: 100%;
    .cardBody {
      box-shadow: 20px 9px 44px rgba(0, 0, 0, 0.12);
      width: 100%;
      border-radius: 10px;
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        color: #3399cc;
        font-size: 40px;
      }
    }
  }
}

.dashboardContainer {
  background: white;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.homeStatisticCard .card .cardBody .content {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px;
  color: black;
}

.homeStatisticCard .card .cardBody .content span {
  display: block;
  color: #3399cc;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  margin-top: 5px;
}
