.loginImageBg {
  background-image: url("../../assets/earth.jpg");
}

.loginPage {
  display: flex;
  height: 100vh;
  padding: 20px;
  background: #eaeaea;
  box-sizing: border-box;
}

.loginImageBg {
  width: 100%;
  border-radius: 10px;
}

.loginImageBg img {
  width: 100%;
}

.login {
  width: 100%;
  padding: 20px;
  background: white;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  flex-flow: wrap;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 430px;
  max-width: 450px;
  @media (max-width: 1024px) {
    width: 70%;
  }
}

* {
  box-sizing: border-box;
}

// .ant-row.ant-form-item-row.css-dev-only-do-not-override-dkbvqv {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
// }

.loginHeader {
  width: 100%;
  margin-bottom: 15px;
}

.formContainer {
  width: 100%;
  .ant-col {
    min-width: 100%;
  }
  .ant-btn {
    width: 100%;
    height: 42px;
    margin-top: 20px;
    border-radius: 30px;
  }
  .loginPassword {
    padding-top: 20px;
  }
  .ant-col {
    text-align: left;
  }
}

.footerLogin {
  width: 100%;
}

.formContainer .loginPassword .ant-input-password {
  border-radius: 30px;
  padding: 10px 20px;
}

.formContainer input {
  border-radius: 30px;
  padding: 10px 20px;
}

.loginHeader svg {
  max-width: 160px;
}

.errorLogin {
  color: red;
  font-size: 18px;
}
