.tokenInvalid {
  text-align: center;
}

.tokenInvalid p {
  font-size: 20px;
  color: #39c;
  font-weight: bold;
}

.tokenInvalid button {
  color: #39c;
  border: 1px solid #39c;
  font-size: 20px;
  height: auto;
  text-transform: uppercase;
  font-weight: bold;
}
