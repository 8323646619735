.userColor {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 10px;
}

.userAvatar img {
  width: 42px;
  border-radius: 50%;
  height: 42px;
  object-fit: cover;
}

.userAvatar {
  border-width: 4px;
  border-style: solid;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
}
