.profileTimeline {
  padding-left: 30px;
}

ul.events {
  padding: 0;
  margin: 0;
}

.profileTimeline .ant-card-body {
  box-shadow: 20px 9px 44px rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: 10px;
  padding: 20px 40px;
  margin: 20px 0 0px 0;
}

.patientPhoto img {
  max-width: 100%;
}

.patientForm input {
  border-radius: 5px;
  background: #ffffff;
}

.patientForm input.ant-input-disabled {
  background: #f0f0f0;
  color: black;
}

.childBodyContainer h3 {
  margin-top: 0;
}
