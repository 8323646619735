.patientsFullList .patientsListItem {
  background: #dfdfdf80;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
}

.warningPatient {
  color: #3399cc;
}

.searchPatients .ant-form-item {
  margin: 0;
}
